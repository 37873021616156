<template>
  <div class="receipt-viewer">
    <div class="receipt-dialog">
      <h2 v-text="$t('components.receipt_viewer.title')" />
      <div class="receipt-carousel">
        <el-carousel
          height="calc(100vh - 175px)"
          :arrow="localReceipts.length > 1 ? 'hover' : 'never'"
          :autoplay="false"
          @change="updateCurrentReceipt"
        >
          <el-carousel-item v-for="(receipt, i) in localReceipts" :key="i">
            <!--need to have one line to retain correct format-->
            <!--eslint-disable-next-line-->
            <el-card class="receipt-card" :id="`receipt-${i}`">{{ receipt.text }}</el-card>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="footer-button">
        <el-button @click="$emit('cancel-requested')">
          {{ $t('common.interactions.buttons.cancel') }}
        </el-button>
        <el-button
          type="primary"
          :disabled="!currentReceipt"
          @click="printCurrentReceipt"
        >
          {{ $t('common.interactions.buttons.print') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import printJS from 'print-js'

export default {
  name: 'ReceiptsViewer',
  props: {
    receiptTitle: {
      type: String,
      required: false,
      default: ''
    },
    resourceId: {
      type: String,
      required: true
    },
    receipts: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      index: 0
    }
  },
  computed: {
    localReceipts() {
      return this.receipts.filter((r) => r.text)
    },
    currentReceipt() {
      if (this.localReceipts.length < 1) return null
      return this.localReceipts[this.index]
    }
  },
  methods: {
    updateCurrentReceipt(index) {
      this.index = index
    },
    printCurrentReceipt() {
      this.print()
      this.$emit('print-receipt', this.currentReceipt)
    },

    print() {
      const title = this.receiptTitle
      const text = this.currentReceipt.text

      const h = `
      <div class="body-wrap">
        <div class="receipt-card">
          <pre>${text}</pre>
        </div>
       </div>
      `

      const s = `
        @page { size: auto;  margin: 0mm; }

        html, body {
          margin: 0;
          padding: 0;
          width: 100%;
        }

        @media print {
          #Header, #Footer { display: none !important; }
        }

        .body-wrap {
          width: 100%;
          display: flex;
          justfiy-content: center;
          align-items: center;
          flex-direction: column;
          margin-top: 20mm;
        }

        pre {
          display: block;
          font-family: monospace;
          white-space: pre;
          margin: 0;
          font-size: 8pt;
          width: 80mm;
          overlow: hidden;
        }
      `

      const capturedTitle = document.title
      document.title = title

      const end = () => {
        this.$nextTick(() => {
          document.title = capturedTitle
        })
      }

      try {
        printJS({
          printable: h,
          type: 'raw-html',
          header: title,
          documentTitle: title,
          style: s,
          scanStyles: false,
          repeatTableHeader: false
        })
      } catch (err) {
        // TODO: i18n
        this.$logException(err, {
          trackError: false,
          message: 'could not print'
        })

        return false
      } finally {
        end()
      }

      return true
    }
  }
}
</script>

<style scoped>
h2 {
  text-align: center;
  color: var(--secondary-color);
}
.receipt-dialog {
  display: flex;
  flex-direction: column;
}
.receipt-card {
  margin: 20px;
  padding: 10px;
  white-space: pre;
  text-align: center;
  height: 90%;
  overflow: auto;
}
.receipt-carousel {
  overflow: hidden;
}
.footer-button {
  margin: 10px;
  text-align: center;
}

.el-card :deep(.el-card__body) {
  font-family: 'Ubuntu-Mono', monospace;
}
</style>
